import React, { useContext, useEffect } from 'react';
import Tabla from '../tabla/Tabla';
import './Calificaciones.scss';
import ComprensionContext from '../../../context/auth/comprensionContext';

const Calificaciones = () => {
	const dataCalificaciones = useContext(ComprensionContext);
	const {consultarCalificacion} = dataCalificaciones;

	useEffect(() => {
		consultarCalificacion();
	}, []);
	return (
		<div className='div-content'>
			<Tabla />
		</div>
	)
}

export default Calificaciones;