import React from 'react';
import {BrowserRouter as Router,
        Routes,
        Route
} from 'react-router-dom';
import Register from './components/register';
import Login from './components/login';
// import PanelPrincipal from './components/actividades'
import ComprensionState from './context/auth/ComprensionState';
import LayoutEstuduante from './Layout';
import Evaluacion from './components/panel/evaluacion';
import Talleres from './components/panel/talleres';
import Interactivo from './components/panel/interactivo';
import Calificaciones from './components/panel/calificaciones';
import PageEvaluacion from './components/panel/evaluacion/pageEvaluacion/PageEvaluacion';
import PageTalleres from './components/panel/evaluacion/pageEvaluacion/PageTalleres';

import './App.scss';

const App = () => {
	return (
		<ComprensionState>
			<Router>
				<Routes>
					<Route exac path={'/'} element={ <Login />} />
					<Route exac path={'/registro'} element={ <Register />} />
					<Route exac path={'/panelEstudiante/taller'} element={ <PageTalleres />} />
					<Route exac path={'/panelEstudiante/evaluacion'} element={ <PageEvaluacion />} />
					<Route element={<LayoutEstuduante/>}>
						<Route exac path={'/panelEstudiante'} element={ <Evaluacion />} />
						<Route exac path={'/talleres'} element={ <Talleres />} />
						<Route exac path={'/interactivo'} element={ <Interactivo />} />
						<Route exac path={'/calificaciones'} element={ <Calificaciones />} />
					</Route>
				</Routes>
			</Router>
		</ComprensionState>
	)
}

export default App