import React from 'react';
import { Progress } from 'antd';
import {colorProgress, claseCalificacion} from '../../../helpers/helpers';


import './Tabla.scss';

const Tabla = () => {
    let notas = JSON.parse(sessionStorage.getItem('calificaciones'));
    const numeros = [0,1,2];

    return (
        <table className="table">
            <thead className='table-light'>
                <tr className='tr'>
                    <th scope="col">Bloque</th>
                    <th scope="col">Tipología</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Nota</th>
                    <th scope="col">Nivel</th>
                </tr>
            </thead>
            <tbody>
                {
                    (notas) ? 
                        notas.map((item, index) => (
                            console.log(item['tipologia']),
                            <tr className='tr' key={index}>
                                <th scope="row">{item.bloque}</th>
                                <td>{item.tipologia}</td>
                                <td>{item.created_at.slice(0,10)}</td>
                                <td><Progress percent={item.nota} strokeColor={colorProgress(item.nivel)}/></td>
                                <td><span className={claseCalificacion(item.nivel)}>{item.nivel}</span></td>
                            </tr>
                        ))
                    :null
                }
            </tbody>
        </table>

  )
}

export default Tabla