import React, {useContext, useEffect, useState} from 'react';
import LogoEditorial from '../../../../images/logo_cardinal.svg';
import LogoEstudianteV from '../../../../images/logo_estudiante_verde.png';
import Temporizador from '../temporizador/Temporizador';
import { calculaRespuesta, valorNivel } from '../../../../helpers/helpers';
import { Button } from 'antd';
import Spinner from '../../../spinner/Spinner';
import {useNavigate} from 'react-router-dom';
import comprensionContext from '../../../../context/auth/comprensionContext';

import './PageEvaluacion.scss';

const PageEvaluacion = () => {
    const validaDatos = JSON.parse(sessionStorage.getItem('datosParaTest'));
    let evalu = JSON.parse(localStorage.getItem('prueba'));
    let dataCliente = JSON.parse(sessionStorage.getItem('cliente'));

    const agregar = useContext(comprensionContext);
    const {usuarioAutenticado, enviado, guardarCalificacion} = agregar;

    const navigate = useNavigate();
    if(!validaDatos){
        navigate('/panelEstudiante')        
    }   
    const cuestionario = evalu.filter((item) => item.bloque === validaDatos.tipologia);
    const [infoTest, setInfoTest] = useState([]);
    const obtenerInfo = (e) => {
        setInfoTest({
            ...infoTest,
            [e.target.name] : [e.target.value]
        });
    }

    const resultado = async (e) => {        
        e.preventDefault();

        const preguntas = cuestionario.filter((pregunta)=> pregunta.tipopregunta != "bloque");
        const nuevoArray = Object.values(infoTest);
        let contador = 0;

        for(let i=0; i<nuevoArray.length; i++){
            if(nuevoArray[i][0] === preguntas[i]['isCorrect']){
                contador++
            }
        }
        const cantidadPreguntas = 20;
        const result = (contador/cantidadPreguntas).toFixed(2);
        const resultFinal = result *100;
        const nivel = valorNivel(resultFinal);
        
        guardarCalificacion({
            bloque: validaDatos.bloque,
            tipologia: validaDatos.tipologia,
            nota: resultFinal,
            nivel: nivel 
        });   
        
    }

    function getComponent(item, index) {
        const letras = ["a", "b", "c", "d"];
        let opciones = ['op1', 'op2', 'op3', 'op4'];

        switch (item.tipopregunta) {
            case 'bloque':
                return(
                    <div className='bloque'>
                         <p className='txt_bloque'>{item.pregunta}</p>
                         <div className="img_bloque">
                             <img src={`/assets/${item.imgpregunta}.png` } alt={item.imgpregunta}/>
                         </div>
                    </div>
                )
            case 'tipo1':                
                return (
                    <div className="tipo1" key={index+1}>
                        <div className="pregunta">
                            <p className="numeracion">{item.numItem}.</p><p className="texto">{item.pregunta}</p>
                        </div>
                        {                            
                            letras.map((opt, index) => (                                
                                <div className="respuestas">
                                    <p className="letras">{letras[index]}.</p>
                                    <input type="radio" name={item.numPregunta} value={opciones[index]} onChange={obtenerInfo}/>
                                    <p className="respuesta">{item[opciones[index]]}</p>
                                </div>
                            ))
                        }
                    </div>
                )
                case 'tipo2':
                    return (
                        <div className="tipo2" key={index+1}>
                            <div className="pregunta">
                                    <p className="numeracion">{item.numItem}.</p><p className="texto">{item.pregunta}</p>
                            </div>
                            <div className="imgpregunta">
                                <img src={`/assets/${item.imgpregunta}.png` } alt={item.imgpregunta}/>
                            </div>
                            {                            
                                letras.map((opt, index) => (                                
                                    <div className="respuestas">
                                        <p className="letras">{letras[index]}.</p>
                                        <input type="radio" name={item.numPregunta} value={opciones[index]} onChange={obtenerInfo}/>
                                        <p className="respuesta">{item[opciones[index]]}</p>
                                    </div>
                                ))
                            }
                        </div>
                    )
                    case 'tipo3':
                        return (
                            <div className="tipo3" key={index+1}>
                                <div className="pregunta">
                                        <p className="numeracion">{item.numItem}.</p>
                                        <p className="texto">{item.pregunta}</p>
                                </div>
                                <div className="preconimg">
                                    {
                                        letras.map((opt, index) => (
                                            <div className="respuestas" key={index+1}>
                                                <p className="letras">{letras[index]}.</p>
                                                <input type="radio" name={item.numPregunta} value={opciones[index]} onChange={obtenerInfo}/>
                                                <img src={`/assets/${item[opciones[index]]}.png`} alt={item[opciones[index]]} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    case 'tipo4':
                        return (
                            <div className="tipo4" key={index+1}>
                                <div className="pregunta">
                                        <p className="numeracion">{item.numItem}.</p>
                                        <p className="texto">{item.pregunta}</p>
                                </div>
                                <img src={`/assets/${item.imgpregunta}.png`} alt={item.imgpregunta} />
                                <div className="preconimg">
                                    {/* {
                                        optPre.map((opt, index) => (
                                            <div className="respuestas" key={index+1}>
                                                <p className="letras">{letras[index]}.</p>
                                                <input type="radio" name={item.numPregunta} value={opt.isCorrect} onChange={obtenerInfo}/>
                                                <img src={`/assets/${opt.op}.png`} alt={opt.op} />
                                            </div>
                                        ))
                                    } */}
                                </div>
                            </div>
                        )
                    case 'tipo5':
                        return(
                            <div className="tipo5" key={index+1}>
                                <div className="pregunta">
                                        <p className="numeracion">{item.numItem}.</p>
                                        <p className="texto">{item.pregunta}</p>
                                </div>
                                <img src={`/assets/${item.imgpregunta}.png`} alt={item.imgpregunta} />
                                <div className="preconimg">
                                    {/* {
                                        optPre.map((opt, index) => (
                                            <div className="respuestas" key={index+1}>
                                                <p className="letras">{letras[index]}.</p>
                                                <input type="radio" name={item.numPregunta} value={opt.isCorrect} onChange={obtenerInfo} />
                                                <img src={`/assets/${opt.op}.png`} alt={opt.op} />
                                            </div>
                                        ))
                                    } */}
                                </div>
                            </div>
                        )
                        case 'tipo6':
                            return(
                                <div className="tipo6" key={index+1}>
                                        <div className="pregunta">
                                            <p className="numeracion">{item.numItem}.</p>
                                            <p className="texto">{item.pregunta}</p>
                                        </div>
                                        <div className="preconimg">
                                        {/* {
                                            optPre.map((opt, index) => (
                                                <div className="respuestas" key={index+1}>
                                                    <p className="letras">{letras[index]}.</p>
                                                    <input type="radio" name={item.numPregunta} value={opt.isCorrect} onChange={obtenerInfo} />
                                                    <img src={`/assets/${opt.op}.png`} alt={opt.op} />
                                                </div>
                                            ))
                                        } */}
                                        </div>
                                    </div>
                            )
        }
    }

    useEffect(() => {
        if(enviado){
            navigate('/panelEstudiante');
        }
    }, [enviado]);

    return (
        <div className='content-eva'>
            <aside>
                <div className="encabezado">
                    <img src={LogoEstudianteV} alt="imagen"/>
                    <p className="textEst">{ (dataCliente) ? dataCliente.nombres : null}</p>
                    <p className="textPerfil">Estudiante</p>
                </div>
                <div className="menu">
                    <div className="tema">
                        <p className="tema_tema">{(validaDatos) ? validaDatos.bloque : null}</p>
                        <p className="tema_nom">{(validaDatos) ? validaDatos.tipologia : null}</p>
                    </div>
                    <div className="crono">
                        <p className="nombre">Tiempo</p>
                        <Temporizador />
                    </div>
                </div>
                <div className="logo_editorial">
                    <img src={LogoEditorial} alt="imagen"/>
                </div>
            </aside>
            <main>
                    <div className="tituloEvaluacion">
                        <p>{(validaDatos) ? validaDatos.tipologia : null}</p>
                    </div>
                    <form>
                        {
                            cuestionario.map((item, index) => getComponent(item, index))
                        }

                        <Button type='primary' shape="round" className='btnEnviar' onClick={resultado}>Terminar Evaluación</Button>
                    </form>

                {/* <div className="tituloEvaluacion">
                    <p>{(validaDatos) ? validaDatos.tipologia : null}</p>
                </div>
                <form onSubmit={resultado}>                    
                    {
                        cuestionario.map((item, index) => getComponent(item, index))
                        
                    }
                    <Button type='primary' shape="round" className='btnEnviar' onClick={resultado}>Terminar Evaluación</Button>                    
                </form> */}
            </main>
        </div>
    )
}

export default PageEvaluacion