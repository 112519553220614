import React from 'react';
import LineDoble from '../../../images/linea_doble.svg';
import IconoInter from '../../../images/icon_interactivo.svg'

const Interactivo = () => {
  return (
    <div className='div-content'>
        <a href='https://artesaniacl.editorialcardinal.com.co/' target="_blank" style={{marginRight:"50px"}}>
            <div className='item-div-content'>
            <div className='logo-anim'>
                <img src={IconoInter}  alt='imagen'/>
            </div>
            <img src={LineDoble}  alt='imagen'/>
            <p>Artesanías P3</p>
            </div>      
        </a>

        <a href='https://mitosyleyendas.editorialcardinal.com.co/' target="_blank" style={{marginRight:"50px"}}>
            <div className='item-div-content'>
            <div className='logo-anim'>
                <img src={IconoInter}  alt='imagen'/>
            </div>
            <img src={LineDoble}  alt='imagen'/>
            <p>Mitos y leyendas P13</p>
            </div>      
        </a>

        <a href='https://youtu.be/I-CPrvKXjO0' target="_blank" style={{marginRight:"50px"}}>
            <div className='item-div-content'>
            <div className='logo-anim'>
                <img src={IconoInter}  alt='imagen'/>
            </div>
            <img src={LineDoble}  alt='imagen'/>
            <p>Video del covid-19 P41</p>
            </div>      
        </a>
    </div>
  )
}

export default Interactivo;