import React, {useContext, useEffect} from 'react';
import LineDoble from '../../../images/linea_doble.svg';
import IconoTaller from '../../../images/icon_talleres.svg'
import comprensionContext from '../../../context/auth/comprensionContext';
import Spinner from '../../spinner/Spinner';
import {NavLink, useNavigate} from 'react-router-dom';


const Talleres = () => {
	let taller = JSON.parse(localStorage.getItem('talleres'));
	const usuario = JSON.parse(sessionStorage.getItem('cliente'));
	
	const dataInit = useContext(comprensionContext);
	const {guardaInfotest, obtenerTalleres, obtenerTaller} = dataInit;
	let navigate = useNavigate();

	const datosTest = (item1, item2) => {
		guardaInfotest(item1, item2);
		// navigate('/panelEstudiante/taller')		
	}

	useEffect(() => {
		obtenerTalleres();
		obtenerTaller()
	}, []);
	return (
		<div className='div-content'>
			{
				(usuario && taller) ?
					taller.map((item, index) => (
						<NavLink className='item-div-content' to="/panelEstudiante/taller" onClick={() =>datosTest(item.tipologia, item.bloque)} key={index}>
							<div className='logo-anim'>
								<img src={IconoTaller} alt='imagen'/>
							</div>
							<img src={LineDoble}  alt='imagen' />
							<p>{`${item.tipologia} ${item.bloque}`}</p>
						</NavLink>
					))
				:<Spinner/>
			}
			{/* {
				(usuario) ? evalu.filter(dataM => dataM.titulotexto === usuario.texto)
							.map((item, index) => (
								<NavLink className='item-div-content' to="/panelEstudiante/taller" onClick={() =>datosTest(item.tipologia, item.bloque)} key={index}>
									<div className='logo-anim'>
										<img src={IconoTaller} alt='imagen'/>
									</div>
									<img src={LineDoble}  alt='imagen' />
									<p>{`${item.tipologia} ${item.bloque}`}</p>
								</NavLink>
							))				
				: <Spinner />
			} */}
		</div>
	)
}

export default Talleres