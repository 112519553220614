import React, {useContext, useState} from 'react';
import {useTimer} from 'react-timer-hook';

import './Temporizador.scss';

function MyTimer({ expiryTimestamp }) {
    const {
		seconds,
		minutes,
		hours,
		start,
		pause,
		resume,
		restart,		
    } = useTimer({ expiryTimestamp, onExpire: () => alert('Tiempo Expirado') });  
  
    return (
		<div className='temporizador'>
			<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
		</div>
    );
  }

const Temporizador = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 300); // Se asigna el tiempo de la prueba al temporizador
	return (  <MyTimer expiryTimestamp={time} />  )
}

export default Temporizador