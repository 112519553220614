import axios from "axios";
import {useState} from "react";

export default function Auth(){
    const authToken = sessionStorage.getItem('token');
    const [token, setToken] = useState(authToken);

    const isLocalhost = Boolean(
        window.location.hostname === "localhost" ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === "[::1]" ||
          // 127.0.0.1/8 is considered localhost for IPv4.
          window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
      
    const SERVER_URL = isLocalhost
    ? "http://127.0.0.1:8000/api/v1"
    : "http://apicl.editorialcardinal.com.co/api/v1";

    const clienteAxios = axios.create({
        baseURL: `${SERVER_URL}`,
        headers:{
            'Content-type': "application/json",
            "Authorization" : `Bearer ${token}`
        },
        withCredentials: true,    
    });

    return {
        clienteAxios,
        setToken
    }
}


























/*
import axios from "axios";

const token = sessionStorage.getItem('token');

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const SERVER_URL = isLocalhost
  ? "http://127.0.0.1:8000/api/v1"
  : "https://server-production-818e.up.railway.app/api";

export const clienteAxios = axios.create({
    baseURL: `${SERVER_URL}`,
    headers:{
        'Content-type': "application/json",
        "Authorization" : `Bearer ${token}`
    },
    withCredentials: true,    
});

*/