import React, {useContext, useEffect } from 'react';
import LineDoble from '../../../images/linea_doble.svg';
import IconoEva from '../../../images/icon_test.svg';
import comprensionContext from '../../../context/auth/comprensionContext';
import Spinner from '../../spinner/Spinner';
import {NavLink, useNavigate} from 'react-router-dom';


import './Evaluacion.scss';
const Evaluacion = () => {	
	let evalu = JSON.parse(localStorage.getItem('evaluaciones'));	
	const usuario = JSON.parse(sessionStorage.getItem('cliente'));

	const dataInit = useContext(comprensionContext);
	const {guardaInfotest, obtenerPrueba, obtenerEvaluaciones} = dataInit;
	let navigate = useNavigate();

	const datosTest = (item1, item2) => {
		guardaInfotest(item1, item2);
		navigate('/panelEstudiante/evaluacion')		
	}

	useEffect(() => {
		obtenerEvaluaciones();
	}, []);
	
	return (
		<div className='div-content'>
			{
				(usuario && evalu) ?  
					evalu.map((item, index) => (
						<NavLink className='item-div-content' to="/panelEstudiante/evaluacion" onClick={() =>datosTest(item.bloque)} key={index}>
							<div className='logo-anim'>
								<img src={IconoEva} alt='imagen'/>
							</div>
							<img src={LineDoble}  alt='imagen' />
							<p>{`${item.bloque}`}</p>
						</NavLink>
					))
				:<Spinner/>
			}
		</div>
	)
}

export default Evaluacion