import React, {useReducer} from 'react';
import comprensionContext from './comprensionContext';
import comprensionReducer from './compresionReducer';
import {
    FORMULARIO_ALERTA,
    OCULTAR_ALERTA,
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    TOKEN_EXITOSO,
    TOKEN_ERROR,
    CHANGE_MENU,
    DATOS_PARA_TEST,
    OBTENER_EVALUACIONES,
    CERRAR_SESION,
    GUARDAR_NOTA,
    MOSTRAR_NOTAS,
    OBTENER_PRUEBA,
    OBTENER_TALLERES,
    OBTENER_TALLER
} from '../../types';

// import { clienteAxios } from '../../config/axios';
import Auth from '../../config/axios';
// import tokenAuth from '../../config/tokenAuth';

const ComprensionState = (props) => {
    const initialState = {
        alerta: false,
        mensajeErrorForm: null,
		mensaje: null,
        usuario: null, 
        autenticado: false,
        registrado: false,
        iconMenu: false,
        timetest: 80,
        informacionTest: null,
        evaluaciones: [],
        cargaData: true,
        enviado: false,
        calificaciones: false       
    }
    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(comprensionReducer, initialState);

    const {clienteAxios, setToken} = Auth();

    //Serie de funciones para modificar el State

    //Modifica el state de alerta
    const formularioAlerta = (msg, cssload) => {
        const alert = {
            msg,
            cssload
        }
        dispatch({
            type: FORMULARIO_ALERTA,
            payload: alert          
        });

        // Después de 3 segundos limpiar la alerta
        setTimeout(() => {
            dispatch({
                type: OCULTAR_ALERTA
            })
        }, 3000);
    }

    //Agregar usuario
    const registrarUsuario = async datos => {
        
		try {
            const respuesta = await clienteAxios.post('/register', datos);
            const alert = {
				msg: respuesta.data.message,
				cssload: 'success'
			} 
			dispatch({
				type: REGISTRO_EXITOSO,
				payload: alert
			});
            setTimeout(() => {
                dispatch({
                    type: OCULTAR_ALERTA
                })
            }, 3000);

            window.location.href = '/';
            
		} catch (error) {
			const alert = {
				msg: Object.values(error.response.data.error)[0],
				cssload: 'error'
			}
			dispatch({
				type: REGISTRO_ERROR,
				payload: alert
			});
            setTimeout(() => {
                dispatch({
                    type: OCULTAR_ALERTA
                })
            }, 2000);            
		}
	}

    //Validar el token del usuario 
    const usuarioAutenticado = async() => {        
        try {
            const respuesta = await clienteAxios.post('/get-user');   
        } catch (error) {
            console.log(error);
            // dispatch({
            //     type: TOKEN_ERROR
            // })
        }
    }

    //Cuando el usuario Inicia Sesion
    const loginUsuario = async (data) => {
        // obtenerEvaluaciones();
        // obtenerPrueba();       
        try {
            const respuesta = await clienteAxios.post('/login', data);   
            dispatch({
                type: LOGIN_EXITOSO,
                payload: respuesta.data,                
            });
            dispatch({
                type: TOKEN_EXITOSO,
                payload: respuesta.data,                
            }); 
            setToken(respuesta.data.token);
        } catch (error) {
            console.log(error);
            const alert = {
                msg: Object.values(error.response.data)[0],
                cssload: 'error'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alert
            })
            setTimeout(() => {
                dispatch({
                    type: OCULTAR_ALERTA
                })
            }, 3000);
        }
             
	}

    //Cambiar el icono del menu en el header.js
    const cambioIconMenu = (menu) => {
        dispatch({
            type: CHANGE_MENU,
            payload: menu
        });
        // window.location.reload()
    }

    //Guarda los datos del test que se va a realizar
    const guardaInfotest =(tipologia, bloque) =>{
        const informacion = {
            tipologia, bloque
        }
        dispatch({
            type: DATOS_PARA_TEST,
            payload: informacion
        })
    }

    //Obtener la evaluaciones de la comprension
    const obtenerEvaluaciones = async () => {
        try {
            const eva = await clienteAxios.post('/evaluaciones');  
            console.log(eva);          
            dispatch({
                type: OBTENER_EVALUACIONES,
                payload: eva.data.datos
            });
            obtenerEvaluacion(); 
        } catch (error) {
            console.log(error);
        }
    }

    const obtenerTalleres = async () => {
        try {
            const talleres = await clienteAxios.post('/talleres');     
            dispatch({
                type: OBTENER_TALLERES,
                payload: talleres.data.datos
            })                      
        } catch (error) {
            console.log(error);
        }
    }

    const obtenerTaller = async () => {
        try {
            const taller = await clienteAxios.post('/taller');      
            dispatch({
                type: OBTENER_TALLER,
                payload: taller.data.preguntas
            })                      
        } catch (error) {
            console.log(error);
        }
    }

    //Obtener la prueba saber de la comprension
    const obtenerEvaluacion = async () => {
        try {
            const evaluacion = await clienteAxios.post('/evaluacion');
            dispatch({
                type: OBTENER_PRUEBA,
                payload: evaluacion.data.preguntas
            })    
                              
        } catch (error) {
            console.log(error);
        }
    }

    const cerrarSesion = async() => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    //Guardar las calificaciones de la evaluacion en la DB
    const guardarCalificacion = async(data) =>{ 
        try {
            const respuesta = await clienteAxios.post('/notas', data);
            dispatch({
                type: GUARDAR_NOTA
            })            
        } catch (error) {
            console.log(error.response);
        }   
    }

    const consultarCalificacion = async () => {
        try {
            const resultado = await clienteAxios.post('/get-notas');
            dispatch({
                type: MOSTRAR_NOTAS,
                payload: resultado.data
            })
        } catch (error) {
            console.log(error.response);
        }
    }

    return (
        <comprensionContext.Provider
            value={{
                alerta: state.alerta,
                mensajeErrorForm: state.mensajeErrorForm,
                autenticado: state.autenticado,
                usuario: state.usuario,
                token: state.token,
                registrado: state.registrado,
                iconMenu: state.iconMenu,
                informacionTest: state.informacionTest,
                evaluaciones: state.evaluaciones,
                cargaData: state.cargaData,
                enviado: state.enviado,
                calificaciones: state.calificaciones,
                formularioAlerta,  
                registrarUsuario,
                loginUsuario,
                cambioIconMenu,
                guardaInfotest,
                obtenerEvaluaciones,
                usuarioAutenticado,
                cerrarSesion,
                guardarCalificacion,
                consultarCalificacion,
                obtenerEvaluacion,
                obtenerTalleres,
                obtenerTaller              
            }}
        >{props.children}
        </comprensionContext.Provider>
    )
}

export default ComprensionState;