import React from 'react';

import './Footer.scss';

const Footer = () => {
	return (
		<div className='pie-pagina'>
			<p>Editorial Cardinal todos los derechos reservados © 2017 - 2022</p>
		</div>
	)
}

export default Footer