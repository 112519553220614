export const FORMULARIO_ALERTA = 'FORMULARIO_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';
export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR ='REGISTRO_ERROR';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR ='LOGIN_ERROR';
export const OBTENER_USUARIO ='OBTENER_USUARIO';
export const CERRAR_SESION ='CERRAR_SESION';
export const TOKEN_ERROR = 'TOKEN_ERROR';
export const TOKEN_EXITOSO = 'TOKEN_EXITOSO';
export const CHANGE_MENU = 'CHANGE_MENU';
export const DATOS_PARA_TEST = 'DATOS_PARA_TEST';
export const OBTENER_EVALUACIONES = 'OBTENER_EVALUACIONES';
export const GUARDAR_NOTA = 'GUARDAR_NOTA';
export const MOSTRAR_NOTAS = 'MOSTRAR_NOTAS';
export const OBTENER_PRUEBA = 'OBTENER_PRUEBA';
export const OBTENER_TALLERES = 'OBTENER_TALLERES';
export const OBTENER_TALLER = 'OBTENER_TALLER';
